import React from "react"
import { jsx, css } from "@emotion/core"
import "typeface-lora"
import { Link } from "gatsby"
import User from "../../static/cil-user.svg"
import Archive from "../../static/cil-storage.svg"

export default class Header extends React.Component {
  state = {
    scrolled: false,
  }

  handleScroll = e => {
    // if (window.scrollY > 150 && !this.state.scrolled) {
    //   this.setState({ scrolled: true })
    // } else if (window.scrollY < 75 && this.state.scrolled) {
    //   this.setState({ scrolled: false })
    // }
  }

  componentDidMount() {
    if (window) {
      window.addEventListener("scroll", this.handleScroll)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    return (
      <>
        <div
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 75px;
            background-color: black;
            color: white;
            text-align: center;
            font-family: "Lora";
            line-height: 75px;
            transition: top 0.8s ease-in-out, background 0.9s linear;
            z-index: 2;
          `}
        >
          <div
            css={css`
              float: left;
              width: 100%;
            `}
          >
            <Link
              css={css`
                color: white !important;
              `}
              to="/"
            >
              just some notes.
            </Link>
          </div>
        </div>
      </>
    )
  }
}
